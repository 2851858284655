import React from "react";
import PropTypes from "prop-types";
import "../styles/YoutubeEmbed.css";

const YoutubeEmbed = ({ embedId }) => (
  <div className="container-md">
    <div className="video-responsive ">
      <iframe
        width="800"
        height="800"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>{" "}
  </div>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
