import React from "react";
import { CarouselHeader } from "./CarouselHeader";
import { CustomCarousel } from "../../components/CustomCarousel";

export const DefaultMobileCarousel = ({ apiData }) => {
  return (
    <div className="d-block d-md-none mb-3">
      <div className="recent mb-3">
        <CarouselHeader routeName={"recent"} text={"Recent"} icon={"clock-o"} />

        <CustomCarousel apiData={apiData}></CustomCarousel>
      </div>

      <div className="ongoing mb-3">
        <CarouselHeader
          routeName={"ongoing"}
          text={"On-Going"}
          icon={"gears2"}
        />

        <CustomCarousel apiData={apiData}></CustomCarousel>
      </div>
      <div className="trending mb-3">
        <CarouselHeader
          routeName={"trending"}
          text={"Trending"}
          icon={"location-arrow"}
        />
      </div>
      <CustomCarousel apiData={apiData}></CustomCarousel>
    </div>
  );
};
