import React, { useRef } from "react";
import Online_Education from "../../assets/images/undraw_Online_learning_re_qw08.svg";
import Teaching from "../../assets/images/undraw_teaching_f1cm.svg";
// import Developer_Activity from "../../assets/images/undraw_developer_activity_bv83.svg";
// import Education from "../../assets/images/undraw_education_f8ru.svg";
import { Button, InputGroup, Input, Icon } from "rsuite";
import { CourseCard } from "./CourseCard";

const scrollToRef = (ref) =>
  window.scroll({
    top: ref.current.offsetTop,
    left:0,
    behavior: "smooth",
  });

export const HomePage = () => {
  const getStartedRef = useRef(null);
  const executeScroll = () => scrollToRef(getStartedRef);
  // console.log(getStartedRef);

  return (
    <div className="HomePage calc_minHeight mt-4 ">
      <div className="container">
        <div className="mb-5">
          <div className="intro_heading_text mb-1">
            Everyone Can Self-Study.
          </div>
          <div className="intro_heading_text2 pb-2">
            Start your online Journey here for&nbsp;
            <span className="span_text">Free</span>.
          </div>
        </div>
        <div className="svg_container mb-5">
          <div className="d-flex align-items-center mb-5">
            <img
              src={Teaching}
              alt="Online Education"
              className="svg_div mr-2"
            />
            <div className="svg_side_text text-center">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              <br></br>
              <ion-icon name="planet-sharp" size="large"></ion-icon>
            </div>
          </div>

          <div className="d-flex align-items-center mb-5">
            <div className="svg_side_text text-center">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              <br></br>
              <ion-icon name="planet-sharp" size="large"></ion-icon>
            </div>
            <img
              src={Online_Education}
              alt="Online Education"
              className="svg_div"
            />
          </div>
        </div>
        <Button
          color="violet"
          block
          className="get_started_btn"
          onClick={executeScroll}
        >
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ fontFamily: "Roboto, sans-serif" }}
          >
            Get Started&nbsp;
            <ion-icon
              name="arrow-forward-circle-outline"
              size="large"
            ></ion-icon>
          </div>
        </Button>
      </div>
      <div className="explore_section pb-5">
        <div className="container">
          <div className="mb-2" ref={getStartedRef}>
            <div className="content_heading_text pt-5 mb-5 d-flex justify-content-center align-items-center">
              Explore courses&nbsp;
              <Icon icon="terminal-line" size="2x" className="mt-1" />
            </div>
          </div>

          <div className="container">
            <div className="mb-3">
              <InputGroup
                // size={"lg"}
                inside
                style={{ width: "100%", marginBottom: 10 }}
              >
                <Input />
                <InputGroup.Button>
                  <Icon icon="search" />
                </InputGroup.Button>
              </InputGroup>
            </div>
            <div className="d-flex justify-content-center align-items-start">
              <div
                className="w-100 d-flex flex-column justify-content-between"
                style={{ alignSelf: "stretch" }}
              >
                <CourseCard height={"90px"} marginBottom={"mb-2"}></CourseCard>
                <CourseCard height={"90px"} marginBottom={"mb-2"}></CourseCard>

                <div className="mb-4">
                  <Button
                    appearance="primary"
                    color="violet"
                    block
                    className="view_all_btn"
                  >
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ fontFamily: "Roboto, sans-serif" }}
                    >
                      View All &nbsp;<ion-icon name="open-outline"></ion-icon>
                    </div>
                  </Button>
                </div>
              </div>

              <div className="mx-2 px-1"></div>

              <div className="w-100">
                <CourseCard height={"135px"} marginBottom={"mb-4"}></CourseCard>
                <CourseCard height={"135px"} marginBottom={"mb-4"}></CourseCard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
