import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import { HomePage } from "./pages/homepage/HomePage";
import { NavBar } from "./pages/homepage/NavBar";
import { WatchListPage } from "./pages/watchlistpage/WatchListPage";
import "./_css_utils/bootstrap-utils.css";
import "./_css_utils/bootstrap-grip.css";
import { AdminPage } from "./pages/adminpage/AdminPage";
import { DetailsPage } from "./pages/detailspage/DetailsPage";
import { FooterPage } from "./pages/footerpage/FooterPage";
import { FavListContext } from "./context/FavListContext";
import { useEffect, useState } from "react";
import { ScrollToTop } from "./components/ScrollToTop";
import { RecentSeeMore } from "./pages/seemorepages/RecentSeeMore";
import { OngoingSeeMore } from "./pages/seemorepages/OngoingSeeMore";
import { TrendingSeeMore } from "./pages/seemorepages/TrendingSeeMore";
import { EntertainmentPage } from "./pages/entertainmentpage/EntertainmentPage";

function App() {
  const [favToLs, setFavToLs] = useState(
    localStorage.getItem("favItemList")
      ? JSON.parse(localStorage.getItem("favItemList"))
      : []
  );

  useEffect(() => {
    localStorage.setItem("favItemList", JSON.stringify(favToLs));
  }, [favToLs]);

  return (
    <FavListContext.Provider value={{ favToLs, setFavToLs }}>
      <div className="App">
        <Router>
          <ScrollToTop />
          <NavBar />
          <div className="height_adjust">
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/entertainment" component={EntertainmentPage} />
              <Route exact path="/watchlist" component={WatchListPage} />
              <Route exact path="/admin" component={AdminPage} />
              <Route exact path="/details/:id" component={DetailsPage} />
              <Route exact path="/recent" component={RecentSeeMore} />
              <Route exact path="/ongoing" component={OngoingSeeMore} />
              <Route exact path="/trending" component={TrendingSeeMore} />
            </Switch>
            <FooterPage></FooterPage>
          </div>
        </Router>
      </div>
    </FavListContext.Provider>
  );
}

export default App;
