import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Icon, Panel } from "rsuite";
import { FavListContext } from "../context/FavListContext";
import { ItemForLs } from "../models/ItemForLs";
import "../styles/MovieCard.css";
import { checkFavOrNot } from "../UtilityFunctions/utils";

export const MovieCard = ({ movie }) => {
  const data = movie;
  const { favToLs, setFavToLs } = useContext(FavListContext);

  return (
    <div className="MovieCard mb-4 d-flex justify-content-center align-items-center">
      {movie && (
        <Link to={`/details/${movie.id}`} style={{ textDecoration: "none" }}>
          <Panel shaded bordered bodyFill className="image_panel">
            <div
              style={{
                backgroundImage: `url(${movie.poster_path})`,
                height: 230,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
            {movie?.title && (
              <div className="py-1 px-1 px-md-0 card_body">{movie.title}</div>
            )}
            <div
              className="add_to_fav"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (checkFavOrNot(data, favToLs)) {
                  setFavToLs(favToLs.filter((el) => el.id !== data.id));
                } else {
                  setFavToLs((prevState) => [
                    ...prevState,
                    ItemForLs(data.id, data.title, data.poster_path),
                  ]);
                }
              }}
            >
              <Icon
                icon={checkFavOrNot(data, favToLs) ? "bookmark" : "bookmark-o"}
                className="p-2"
                size="lg"
              />
            </div>
          </Panel>
        </Link>
      )}
    </div>
  );
};
