import React, { useEffect, useState } from "react";
import { CustomLoadingIndicator } from "../../components/CustomLoadingIndicator";
import { APIDATA } from "../../Services/data";
import "../../styles/HomePage.css";
import { DefaultMobileCarousel } from "../entertainmentpage/DefaultMobileCarousel"; 
import { SwiperJsWebCarousel } from "../entertainmentpage/SwiperJsWebCarousel";

export const EntertainmentPage = () => {
   const [apiData, setapiData] = useState([]);
   const [isLoading, setisLoading] = useState(true);
 
   useEffect(() => {
     setTimeout(() => {
       setapiData(APIDATA);
       setisLoading(false);
     }, Math.floor(Math.random() * 501));
   }, [apiData]);
 
   // const [apiData, setApiData] = useState([]);
 
   // useEffect(() => {
   //   Axios.get(
   //     "https://themoviemm.github.io/Data/dummy.json"
   //   )
   //     .then((res) => {
   //       console.log(res.data);
   //       setApiData(res.data);
   //     })
   //     .catch((err) => setApiData([]));
   // }, []);
 
   return (
     <div className="EntertainmentPage calc_minHeight mt-4 container">
       {isLoading ? (
         <CustomLoadingIndicator></CustomLoadingIndicator>
       ) : (
         <>
           {/* For Desktop */}
           {/* <FlexboxGrid className="mb-3 d-none d-md-block">
             {apiData &&
               apiData.map((movie) => {
                 return (
                   <FlexboxGrid.Item
                     key={movie.id}
                     componentClass={Col}
                     colspan={6}
                     md={6}
                     sm={12}
                     xs={12}
                     className="d-flex justify-content-center align-items-center"
                   >
                     <MovieCard movie={movie} />
                   </FlexboxGrid.Item>
                 );
               })}
           </FlexboxGrid> */}
           <div className="mb-3 d-none d-md-block">
             <SwiperJsWebCarousel apiData={apiData}></SwiperJsWebCarousel>
           </div>
 
           {/* For Mobile */}
           <DefaultMobileCarousel apiData={apiData} ></DefaultMobileCarousel>
         </>
       )}
     </div>
   );
}
