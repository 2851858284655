import React, { useContext } from "react";
import { FavListContext } from "../../context/FavListContext";
import { MovieCard } from "../../components/MovieCard";
import { Col, FlexboxGrid } from "rsuite";

export const WatchListPage = () => {
  const { favToLs } = useContext(FavListContext);
  const favList = favToLs;

  return (
    <div className="container calc_minHeight mt-4">
      <FlexboxGrid className="mb-3">
        {favList?.length > 0 ? (
          favList.map((el) => (
            <FlexboxGrid.Item
              key={el.id}
              componentClass={Col}
              colspan={6}
              md={6}
              sm={12}
              xs={12}
              className="d-flex justify-content-center align-items-center"
            >
              <MovieCard movie={el} />
            </FlexboxGrid.Item>
          ))
        ) : (
          <div>No Items in favourite list</div>
        )}
      </FlexboxGrid>
    </div>
  );
};
