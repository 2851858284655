import React from "react";
import { MovieCard } from "./MovieCard";
import "../styles/CustomCarousel.css";




export const CustomCarousel = ({ apiData }) => {
 

  return (
    <div className="carousel_container d-flex align-items-center mb-md-4">
    {apiData &&
      apiData.map((movie, index) => (
        <div className="carousel_item mr-4 mb-md-2" key={index}>
          <MovieCard movie={movie} />
        </div>
      ))}
  </div>
  );
};
