import React from "react";
import "../../styles/CourseCard.css";
import book from "../../assets/images/book.svg";

import { Icon } from "rsuite";

export const CourseCard = ({ height}) => {
  return (
    <div
      className="CourseCard p-1 w-100 mb-4 d-flex flex-column justify-content-between"
      style={{ minHeight: height }}
    >
      <div
        className={`d-flex justify-content-between align-items-start w-100 mb-3`}
      >
        <div className="add_to_favourite">
          <Icon icon={"bookmark-o"} className="p-2" />
        </div>
        <div className="img_container">
          <img
            src={book}
            alt="Book"
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
        </div>
      </div>

      <div style={{ fontSize: 13 }}> Lorem ipsum doloo, similique?</div>
    </div>
  );
};
