import React from "react";
import { useHistory } from "react-router-dom";
import {  Drawer, Icon, Nav, Sidenav } from "rsuite";
import "../../styles/SideDrawer.css"

export const SideDrawer = ({ openSideDrawer, setopenSideDrawer }) => {
  const history = useHistory();
  const handleSelect = (routeValue) => {
    routeValue && history.push(`${routeValue}`);
    setopenSideDrawer(false);
  };

  return (
    <div className="SideDrawer">
      <Drawer
        size="xs"
        style={{ maxWidth: "100%",}}
        placement="top"
        show={openSideDrawer}
        onHide={() => setopenSideDrawer(false)}
      >
        <Drawer.Header>
          <Drawer.Title style={{ color: "#00a0bd" }}>The-Movie</Drawer.Title>
        </Drawer.Header>

        <Drawer.Body className="mb-3">
          <Sidenav appearance="subtle">
            <Nav>
              <Nav.Item
                icon={<Icon icon="dashboard" style={{ paddingTop: 2 }} />}
                onClick={() => handleSelect("/")}
              >
                Home
              </Nav.Item>
              <Nav.Item
                icon={<Icon icon="peoples" style={{ paddingTop: 2 }} />}
                onClick={() => handleSelect("/entertainment")}
              >
                Entertainment
              </Nav.Item>
              <Nav.Item
                icon={<Icon icon="bookmark-o" style={{ paddingTop: 3 }} />}
                onClick={() => handleSelect("/watchlist")}
              >
                Watchlist
              </Nav.Item>
            </Nav>
          </Sidenav>
        </Drawer.Body>
        {/* <Drawer.Footer className="d-flex justify-content-center align-items-center">
          <Button
            block
            onClick={() => handleSelect("/admin")}
            appearance="primary"
          >
            Admin Console
          </Button>
        </Drawer.Footer> */}
      </Drawer>
    </div>
  );
};
