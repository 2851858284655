import React, { useContext } from "react";
import "../../styles/DetailsPage.css";
import { Col, Divider, Grid, Icon, Row } from "rsuite";
import { APIDATA } from "../../Services/data";
import YoutubeEmbed from "../../components/YoutubeEmbed";
import { CustomDivider } from "../../components/CustomDivider";
import { Link } from "react-router-dom";
import { FavListContext } from "../../context/FavListContext";
import { checkFavOrNot } from "../../UtilityFunctions/utils";
import { Avatar, Panel } from "rsuite";
import { ItemForLs } from "../../models/ItemForLs";

export const DetailsPage = ({ match }) => {
  // const [data, setData] = useState({});
  // const [genreArr, setGenreArr] = useState([]);

  // useEffect(() => {
  //   axios
  //     .get("https://themoviemm.github.io/Data/dummy.json")
  //     .then((res) => {
  //       if (res.data.length > 0) {
  //         let result = res.data.find((el) => el.id === Number(match.params.id));
  //         setData(result);

  //         setGenreArr(result.genres.split("|"));
  //       }
  //     })
  //     .catch((err) => setData({}));
  // }, [match.params.id]);

  const { setFavToLs, favToLs } = useContext(FavListContext);

  const data = APIDATA.find((el) => el.id === Number(match.params.id));
  const genreArr = data.genres.split("|");

  return (
    <div className="DetailsPage">
      {data && (
        <div>
          <div className="d-block stack_parent mb-3 mt-md-3">
            <YoutubeEmbed embedId={data.youtubeEmbedId} />
          </div>
          <div className="DetailsContent container">
            <h4 className="mb-3">{data.title} </h4>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div className="ml-1 pb-3 d-flex align-items-center genres">
                {genreArr?.length > 0 ? (
                  genreArr.map((genre, index) => {
                    if (genre.length > 0) {
                      return (
                        <div
                          className="genres_box px-2 mr-2 d-inline-block"
                          key={index}
                        >
                          <div className=""> {genre} </div>
                        </div>
                      );
                    } else {
                      return <div>&nbsp;</div>;
                    }
                  })
                ) : (
                  <div>No Data for Genres</div>
                )}
              </div>

              <div
                className="d-flex justify-content-between align-items-center mb-3 mr-3 w-25"
                style={{ maxWidth: 80 }}
              >
                <div className="rating_box px-2">
                  <Icon icon="star" className="text-warning" />
                  &nbsp; {data.rating}
                </div>
                <div
                  className="favourite_box ml-1"
                  onClick={() => {
                    if (checkFavOrNot(data, favToLs)) {
                      setFavToLs(favToLs.filter((el) => el.id !== data.id));
                    } else {
                      setFavToLs((prevState) => [
                        ...prevState,
                        ItemForLs(data.id, data.title, data.poster_path),
                      ]);
                      localStorage.setItem(
                        "favItemList",
                        JSON.stringify(favToLs)
                      );
                    }
                  }}
                >
                  <Icon
                    icon={
                      checkFavOrNot(data, favToLs) ? "bookmark" : "bookmark-o"
                    }
                    style={{ fontSize: 20 }}
                  />
                </div>
              </div>
            </div>
            <Divider className="mt-1 mb-3" />
            <Panel header="Description" collapsible className="description">
              {data.description}
            </Panel>
            <div className="ml-3 pl-1 d-flex justify-content-start mb-4 mt-2 ">
              <div className="mr-3" style={{ marginTop: 1.5 }}>
                Watch Time
              </div>

              <div className="" style={{ marginTop: 1 }}>
                <Icon icon="clock-o" />
                &nbsp; {data.watch_time}
              </div>
            </div>
            <Divider className="mt-1 mb-3" />
            <div className="ml-2 pb-3 mb-2">
              <div className="d-flex " style={{ fontSize: 18 }}>
                Download Link &nbsp;
                <Icon
                  icon="angle-double-down"
                  className="icon_color_blue icon_spacing"
                  size="lg"
                />
              </div>
              <CustomDivider />
            </div>
            <div className="ml-2 cstm_downlink_group  mt-3">
              <Grid fluid>
                <Row className="ml-3">
                  {data.downloadLinks.length > 1 ? (
                    data.downloadLinks.map((el, index) => {
                      return (
                        <Col
                          xs={10}
                          sm={10}
                          md={4}
                          className="cstm_downlink mb-4 mr-4"
                          key={index}
                        >
                          <Link
                            to={{ pathname: el.link }}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: "none",
                              display: "block",
                            }}
                            className="px-3 mx-1 py-2 text-center text-md-left"
                          >
                            <div className="px-4">
                              Ep{el.episode}
                              {el.recently_added && (
                                <span className="new_item"></span>
                              )}
                            </div>
                          </Link>
                        </Col>
                      );
                    })
                  ) : (
                    <div></div>
                  )}
                </Row>
              </Grid>
            </div>

            {data.casts?.length > 0 && (
              <>
                <Divider className="mt-2" />
                <div className="ml-2 cast_section mb-5">
                  <div className="mb-4" style={{ fontSize: 20 }}>
                    Casts
                  </div>
                  <div className="casts_img d-flex align-items-center">
                    {data.casts?.length > 0 &&
                      data.casts.map((el, index) => (
                        <div
                          className="px--md-2 mr-md-3 d-flex flex-column justify-content-center align-items-center"
                          style={{ minWidth: 90 }}
                          key={index}
                        >
                          <Avatar
                            circle
                            size="lg"
                            src={el.poster_path}
                            className="mx-md-5"
                          ></Avatar>
                          <div className="text-center mt-1">{el.name}</div>
                        </div>
                      ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
