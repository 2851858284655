import React from "react";
import { Icon } from "rsuite";
import "../../styles/FooterPage.css";

export const FooterPage = () => {
  return (
    <div className="FooterPage FooterPage_container">
      <div className="container d-flex justify-content-between align-items-center">
        <div className="ml-1">
          <span className="copy_sign">&copy; 2021</span>&thinsp;The-Movie
        </div>

        <div className="d-flex justify-content-between align-items-center social_div">
          <div> Follow Us on</div>
          <div className="social_icons_group ml-md-3 ml-2">
            <Icon size="lg" icon="facebook-square" className="mx-md-2" />
            <Icon size="lg" icon="telegram" className="mx-md-3 " />
          </div>
        </div>

        <div className="d-none contact_us d-md-block">Contact Us</div>
      </div>
    </div>
  );
};
