import React from "react";
import { MovieCard } from "../../components/MovieCard";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Scrollbar } from "swiper";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import { CarouselHeader } from "./CarouselHeader";
import "../../styles/SwiperJsWebCarousel.css";

SwiperCore.use([Scrollbar]);

export const SwiperJsWebCarousel = ({ apiData }) => {
  return (
    <div className="SwiperjsWebCarousel mb-5">
      <div className="recent_web mb-5">
        <CarouselHeader
          routeName={"recent"}
          text={"Recent"}
          icon={"clock-o"}
        ></CarouselHeader>
        <Swiper
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          spaceBetween={10}
          slidesPerView={5}
          breakpoints={{
            768: {
              slidesPerView: 4,
              spaceBetween: 5,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 10,
            },
          }}
        >
          {apiData?.length > 0 &&
            apiData.map((el, index) => (
              <SwiperSlide key={index}>
                <MovieCard movie={el}></MovieCard>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>

      <div className="ongoing_web mb-5">
        <CarouselHeader
          routeName={"ongoing"}
          text={"On-Going"}
          icon={"gears2"}
        />
        <Swiper
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          spaceBetween={10}
          slidesPerView={5}
          breakpoints={{
            768: {
              slidesPerView: 4,
              spaceBetween: 5,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 10,
            },
          }}
        >
          {apiData?.length > 0 &&
            apiData.map((el, index) => (
              <SwiperSlide key={index}>
                <MovieCard movie={el}></MovieCard>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
      <div className="trending_web mb-5">
        <CarouselHeader
          routeName={"trending"}
          text={"Trending"}
          icon={"location-arrow"}
        />
        <Swiper
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          
          spaceBetween={10}
          slidesPerView={5}
          breakpoints={{
            768: {
              slidesPerView: 4,
              spaceBetween: 5,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 10,
            },
          }}
        >
          {apiData?.length > 0 &&
            apiData.map((el, index) => (
              <SwiperSlide key={index}>
                <MovieCard movie={el}></MovieCard>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
};
