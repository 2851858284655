import React from "react";
import { useHistory } from "react-router-dom";
import { Icon } from "rsuite";

export const CarouselHeader = ({ routeName, text, icon }) => {
  const history = useHistory();
  return (
    <div className="d-flex justify-content-between align-items-center mb-3 p-md-2">
      <div
        className="text_gradient d-flex align-items-center ml-md-2"
        style={{ fontSize: 24 }}
      >
        {text}
        <Icon
          icon={icon}
          style={{ fontSize: 18, marginLeft: 10, marginTop: 3 }}
        />
      </div>

      <div
        className="btn_font_style mr-md-4"
        onClick={() => {
          history.push(`/${routeName}`);
        }}
      >
        See More &#8640;
      </div>
    </div>
  );
};
