import React, { useState } from "react";
import { Icon, Button } from "rsuite";
import { useHistory } from "react-router-dom";
import { SideDrawer } from "./SideDrawer";
import "../../styles/NavBar.css";

export const NavBar = () => {
  const history = useHistory();
  const [openSideDrawer, setopenSideDrawer] = useState(false);

  return (
    <div className="NavBar d-flex align-items-center">
      <div className="d-flex justify-content-between w-100 align-items-center  h-100">
        <Button
          style={{ border: "none !important", fontSize: 18 }}
          color="cyan"
          appearance="ghost"
          onClick={() => {
            // window.scrollTo(0, 0);
            history.push("/");
          }}
        >
          The-Movie
        </Button>
        <div
          className="p-3"
          onClick={() => {
            setopenSideDrawer(!openSideDrawer);
          }}
        >
          <Icon icon="bars" size="lg" style={{ color: "#00a0bd" }} />
        </div>
      </div>
      <SideDrawer
        openSideDrawer={openSideDrawer}
        setopenSideDrawer={setopenSideDrawer}
      />
    </div>
  );
};
