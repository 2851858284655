import React from "react";
import { Divider } from "rsuite";

export const CustomDivider = ({className = ""}) => {
  return (
    <Divider
      className={`CustomDivider my-1 ${className}`}
      style={{
        background: "linear-gradient(to left, #b2fefa, #0ed2f7)",
        height: "4px",
        width: "30px",
        borderRadius: "40px",
      }}
    />
  );
};
