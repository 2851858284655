import React from 'react'

export const RecentSeeMore = () => {
   return (
      <div  className="calc_minHeight mt-4 container">
         Recent See More
      </div>
   )
}


